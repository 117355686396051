<template>
    <div>
        <CRow class="my-3">
            <CCol sm="12">
                <CTabs variant="tabs" :active-tab="0">
                    <CTab :title="$t('label.PackagingBlInformation')">
                        <CCard class="card-border">
                            <CRow class="mb-3">
                                <CCol sm="12" lg="12">
                                    <div class="d-flex justify-content-end" style="font-size: 8px;">
                                        <CCol  class="d-flex col-sm-auto">
                                            <span class="circle mr-2" style="background: #669bbc;"></span>
                                            <b>{{ $t('label.planned') }}</b>
                                        </CCol>
                                        <CCol  class="d-flex col-sm-auto">
                                            <span class="circle mr-2" style="background: #42aa91;"></span>
                                            <b>{{ $t('label.received') }}</b>
                                        </CCol>
                                        <CCol  class="d-flex col-sm-auto">
                                            <span class="circle mr-2" style="background: #FF8000;"></span>
                                            <b>{{ $t('label.pendingReceive') }}</b>
                                        </CCol>
                                    </div>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12" lg="10">
                                    <CRow class="px-3 m-0">
                                        <CCol sm="12" lg="5">
                                            <div class="form-group form-row">
                                                <div class="input-group col-sm-12 col-lg-3 input-group-sm justify-content-end">
                                                    <CSwitch
                                                        color="watch"
                                                        variant="3d"
                                                        type="checkbox"
                                                        :checked.sync="$v.Form.Packaging.VolumeCheck.$model"
                                                        @change.native="(event)=> changeVolumeCheck(event)"
                                                        :disabled="isWasRequested || (!FgHabilitedForm && isEditPackaging && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0)>0 && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0) == setNumber(this.ModuleForm.PackagingItem.Quantity ?? 0))"
                                                        
                                                    />
                                                </div>
                                                <label class="col-sm-12 col-lg-auto col-form-label-sm d-flex justify-content-end">{{`${$t('label.automaticCalculation')} (M³)`}}</label>
                                            </div>
                                        </CCol>
                                        <CCol sm="12" lg="3"></CCol>
                                    </CRow> 
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="12" lg="12">
                                    <CRow>
                                        <CCol sm="12" lg="3">
                                            <CInput
                                                size="sm"
                                                :label="`${$t('label.bl')}`"
                                                addLabelClasses="text-right"
                                                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                v-uppercase
                                                v-model.trim="Form.Packaging.BlName"
                                                :disabled="true"
                                            >
                                            </CInput>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <CSelect
                                                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                :label="$t('label.packaging')"
                                                :options="packagingOptions"
                                                addLabelClasses="required text-right"
                                                v-model.trim="$v.Form.Packaging.BlCargoMasterDetailId.$model"
                                                :is-valid="hasError($v.Form.Packaging.BlCargoMasterDetailId)"
                                                :invalid-feedback="errorMessage($v.Form.Packaging.BlCargoMasterDetailId)"
                                                size="sm"
                                                @blur="$v.Form.Packaging.BlCargoMasterDetailId.$touch()"
                                                @change="changePackagingsValues"
                                                :disabled="isWasRequested || isEditPackaging"
                                            />
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <CInput
                                                size="sm"
                                                :label="`${$t('label.IMO')}`"
                                                addLabelClasses="text-right"
                                                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                v-uppercase
                                                v-model.trim="Form.Packaging.Imo"
                                                :disabled="true"
                                            >
                                            </CInput>
                                        </CCol>
                                        <CCol sm="12" lg="3" class="d-none d-lg-block d-flex align-items-center justify-content-center">
                                            <CCol sm="12" lg="8" class="px-1 d-flex align-items-center justify-content-center mb-3">
                                                <div class="block-blue text-center d-flex align-items-center" style="height: calc(1.5em + 0.5rem + 2px);">
                                                    <div class="w-100 text-black">{{ FormatNumber(PlannedQuantityReceived ?? 0, 2) }}</div>
                                                </div>
                                            </CCol>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <CInput
                                                size="sm"
                                                :label="`${$t('label.imdgClass')}`"
                                                addLabelClasses="text-right"
                                                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                v-uppercase
                                                v-model.trim="Form.Packaging.ImdgClass"
                                                :disabled="true"
                                            >
                                            </CInput>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <CInput
                                                size="sm"
                                                :label="`${$t('label.UnNumber')}`"
                                                addLabelClasses="text-right"
                                                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                v-uppercase
                                                v-model.trim="Form.Packaging.UnNumber"
                                                :disabled="true"
                                            >
                                            </CInput>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <CInput
                                                size="sm"
                                                :label="`${$t('label.containerGroup')}`"
                                                addLabelClasses="text-right"
                                                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                v-uppercase
                                                v-model.trim="Form.Packaging.PackagingGroup"
                                                :disabled="true"
                                            >
                                            </CInput>
                                        </CCol>
                                        <CCol sm="12" lg="3" class="d-none d-lg-block d-flex align-items-center justify-content-center">
                                            <CCol sm="12" lg="8" class="px-1 d-flex align-items-center justify-content-center mb-3">
                                                <div class="block-green text-center d-flex align-items-center" style="height: calc(1.5em + 0.5rem + 2px);">
                                                    <div class="w-100 text-black">{{ FormatNumber(QuantityReceived ?? 0, 2) }}</div>
                                                </div>
                                            </CCol>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <div class="form-group form-row">
                                                <label class="text-right col-sm-12 col-lg-5 col-form-label-sm mb-0 required">{{`${$t('label.quantity')}`}}</label>
                                                <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                    <money
                                                        size="sm"
                                                        v-bind="measure" 
                                                        :class="!$v.Form.Packaging.Quantity.$dirty ? 'form-control' : ($v.Form.Packaging.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                        v-model="$v.Form.Packaging.Quantity.$model"
                                                        addLabelClasses="required text-right"
                                                        maxlength= "12"
                                                        :disabled="isWasRequested"
                                                    >
                                                    </money>
                                                    <div class="invalid-feedback" v-if="$v.Form.Packaging.Quantity.$error">
                                                        {{ errorMessage($v.Form.Packaging.Quantity) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <div class="form-group form-row">
                                                <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{`${$t('label.weight')} (KGM)`}}</label>
                                                <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                    <money
                                                        size="sm"
                                                        v-bind="measureDecimals" 
                                                        :class="!$v.Form.Packaging.Weigth.$dirty ? 'form-control' : ($v.Form.Packaging.Weigth.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                        v-model="$v.Form.Packaging.Weigth.$model"
                                                        addLabelClasses="required text-right"
                                                        maxlength= "13"
                                                        :disabled="isWasRequested || (!FgHabilitedForm && isEditPackaging && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0)>0 && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0) == setNumber(this.ModuleForm.PackagingItem.Quantity ?? 0))"
                                                        
                                                    >
                                                    </money>
                                                    <div class="invalid-feedback" v-if="$v.Form.Packaging.Weigth.$error">
                                                    {{ errorMessage($v.Form.Packaging.Weigth) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <div class="form-group form-row">
                                                <label class="text-right col-sm-12 col-lg-5 col-form-label-sm px-0">{{`${$t('label.volume')} (M³)`}}</label>
                                                <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                    <money
                                                        v-if="!Form.Packaging.VolumeCheck"
                                                        size="sm"
                                                        v-bind="measureDecimals"
                                                        :class="!$v.Form.Packaging.Volumen.$dirty ? 'form-control' : ($v.Form.Packaging.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                        v-model="Form.Packaging.Volumen"
                                                        addLabelClasses="required text-right"
                                                        maxlength= "13"
                                                        :disabled="isWasRequested || (!FgHabilitedForm && isEditPackaging && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0)>0 && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0) == setNumber(this.ModuleForm.PackagingItem.Quantity ?? 0))"
                                                        
                                                    >
                                                    </money>
                                                    <money
                                                        v-else
                                                        size="sm"
                                                        v-bind="measureDecimals"
                                                        :class="!$v.Form.Packaging.Volumen.$dirty ? 'form-control' : ($v.Form.Packaging.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                        v-model="Meters.Volumen"
                                                        addLabelClasses="required text-right"
                                                        maxlength= "13"
                                                        disabled
                                                    >
                                                    </money>
                                                    <div class="invalid-feedback" v-if="$v.Form.Packaging.Volumen.$error">
                                                        {{ errorMessage($v.Form.Packaging.Volumen) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </CCol>
                                        <CCol sm="12" lg="3" class="d-none d-lg-block d-flex align-items-center justify-content-center">
                                            <CCol sm="12" lg="8" class="px-1 d-flex align-items-center justify-content-center mb-3">
                                                <div class="block-orange text-center d-flex align-items-center" style="height: calc(1.5em + 0.5rem + 2px);">
                                                    <div class="w-100 text-black">{{ FormatNumber(QuantityRemainReceived ?? 0, 2) }}</div>
                                                </div>
                                            </CCol>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <div class="form-group form-row">
                                                <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{`${$t('label.length')} (M)`}}</label>
                                                <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                    <money
                                                        size="sm"
                                                        v-bind="measureDecimals" 
                                                        :class="!$v.Form.Packaging.Length.$dirty ? 'form-control' : ($v.Form.Packaging.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                        v-model="$v.Form.Packaging.Length.$model"
                                                        addLabelClasses="text-right"
                                                        maxlength= "13"
                                                        :disabled="isWasRequested || ((!FgHabilitedForm && isEditPackaging && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0)>0 && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0) == setNumber(this.ModuleForm.PackagingItem.Quantity ?? 0))) || !Form.Packaging.VolumeCheck"
                                                    >
                                                    </money>
                                                    <div class="invalid-feedback" v-if="$v.Form.Packaging.Length.$error">
                                                        {{ errorMessage($v.Form.Packaging.Length) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <div class="form-group form-row">
                                                <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{`${$t('label.width')} (M)`}}</label>
                                                <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                    <money
                                                        size="sm"
                                                        v-bind="measureDecimals" 
                                                        :class="!$v.Form.Packaging.Width.$dirty ? 'form-control' : ($v.Form.Packaging.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                        v-model="$v.Form.Packaging.Width.$model"
                                                        addLabelClasses="text-right"
                                                        maxlength= "13"
                                                        :disabled= "isWasRequested || ((!FgHabilitedForm && isEditPackaging && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0)>0 && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0) == setNumber(this.ModuleForm.PackagingItem.Quantity ?? 0))) || !Form.Packaging.VolumeCheck"
                                                    >
                                                    </money>
                                                    <div class="invalid-feedback" v-if="$v.Form.Packaging.Width.$error">
                                                        {{ errorMessage($v.Form.Packaging.Width) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <div class="form-group form-row">
                                                <label class="text-right col-sm-12 col-lg-5 col-form-label-sm">{{`${$t('label.height')} (M)`}}</label>
                                                <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                                                    <money
                                                        size="sm"
                                                        v-bind="measureDecimals" 
                                                        :class="!$v.Form.Packaging.Height.$dirty ? 'form-control' : ($v.Form.Packaging.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                        v-model="$v.Form.Packaging.Height.$model"
                                                        addLabelClasses="text-right"
                                                        maxlength= "13"
                                                        :disabled="isWasRequested || ((!FgHabilitedForm && isEditPackaging && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0)>0 && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0) == setNumber(this.ModuleForm.PackagingItem.Quantity ?? 0))) || !Form.Packaging.VolumeCheck"
                                                    >
                                                    </money>
                                                    <div class="invalid-feedback" v-if="$v.Form.Packaging.Height.$error">
                                                        {{ errorMessage($v.Form.Packaging.Height) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                        </CCol>
                                        <CCol sm="12" lg="3">
                                            <CSelect
                                                :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                                                :label="$t('label.location')"
                                                :options="ubicationOptions"
                                                addLabelClasses="text-right"
                                                v-model.trim="$v.Form.Packaging.YardAreaId.$model"
                                                :is-valid="hasError($v.Form.Packaging.YardAreaId)"
                                                :invalid-feedback="errorMessage($v.Form.Packaging.YardAreaId)"
                                                size="sm"
                                                @blur="$v.Form.Packaging.YardAreaId.$touch()"
                                                :disabled="isWasRequested"
                                            />
                                        </CCol>
                                    </CRow> 
                                </CCol>
                                <CCol sm="12" lg="2" class="d-none d-sm-block d-md-block d-lg-none">
                                    <CCol sm="12" lg="8" class="px-1 d-flex align-items-center justify-content-center mb-3">
                                        <div class="block-blue text-center d-flex align-items-center" style="height: calc(1.5em + 0.5rem + 2px);">
                                            <div class="w-100 text-black">{{ FormatNumber(PlannedQuantityReceived ?? 0, 2) }}</div>
                                        </div>
                                    </CCol>

                    
                                    <CCol sm="12" lg="8" class="px-1 d-flex align-items-center justify-content-center mb-3">
                                        <div class="block-green text-center d-flex align-items-center" style="height: calc(1.5em + 0.5rem + 2px);">
                                            <div class="w-100 text-black">{{ FormatNumber(QuantityReceived ?? 0, 2) }}</div>
                                        </div>
                                    </CCol>


                                
                                    <CCol sm="12" lg="8" class="px-1 d-flex align-items-center justify-content-center mb-3">
                                        <div class="block-orange text-center d-flex align-items-center" style="height: calc(1.5em + 0.5rem + 2px);">
                                            <div class="w-100 text-black">{{ FormatNumber(QuantityRemainReceived ?? 0, 2) }}</div>
                                        </div>
                                    </CCol>
                                </CCol>
                            </CRow>
                            <CRow v-if="!isWasRequested">
                                <CCol sm="12">
                                    <CCol class="d-flex justify-content-end col-form-label p-0 col-sm-12 col-lg-12 col-form-label-sm mb-2">
                                        <CButton
                                            color="add"
                                            size="sm"
                                            class="mr-1"
                                            v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}"
                                            @click="SubmitPackaging()"
                                        >
                                            <CIcon name="checkAlt"/>
                                        </CButton>
                                        <CButton
                                            color="wipe"
                                            class="mr-1"
                                            size="sm"
                                            v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}"
                                            @click="clearFilters()"
                                        >
                                            <CIcon name="cil-brush-alt" />
                                        </CButton>
                                    </CCol>
                                </CCol>
                            </CRow>
                        </CCard>
                    </CTab>
                </CTabs>
            </CCol>

            <CCol sm="12">
                <CTabs variant="tabs" :active-tab="0">
                    <CTab :title="$t('label.packagingsSerial')">
                        <CCol sm="12" lg="12" class="justify-content-center">
                            <dataTableExtended
                                class="align-center-row-datatable"
                                :items-per-page="5"
                                :items="ComputedPackaging"
                                :fields="Packagingfields"
                                column-filter
                                pagination
                                :table-filter="tableText.tableFilterText"
                                :items-per-page-select="tableText.itemsPerPageText"
                                :noItemsView="tableText.noItemsViewText"
                                sorter
                            >

                                <template #Status="{ item }">
                                    <td class="text-center">
                                        <CBadge :color="getBadge(item.Status)">
                                            {{ $t('label.'+item.Status) }}
                                        </CBadge>
                                    </td>
                                </template>
                                
                                <template #options="{ item }">
                                    <td class="text-center">
                                        <CButton
                                            color="edit"
                                            square
                                            size="sm"
                                            class="mr-2"
                                            v-c-tooltip="{
                                                content: $t('label.edit'),
                                                placement: 'top-start'
                                                }"
                                            @click="EditPackaging(item)"
                                        >
                                            <CIcon name="pencil" />
                                        </CButton>
                                        <CButton
                                            v-if="item.FgRequiredSerialization"
                                            square
                                            size="sm"
                                            color="watch"
                                            class="mr-2"
                                            @click="OnSerials(item)"
                                            v-c-tooltip="{
                                                content: $t('label.serials'),
                                                placement: 'top-start'
                                            }"
                                        >
                                            <CIcon name='cil-list' />
                                        </CButton>
                                        <CButton
                                            class="btn btn-sm btn-wipe"
                                            v-c-tooltip="{
                                                content: $t('label.delete'),
                                                placement: 'top-start',
                                            }"
                                            @click="DeletePackaging(item)"
                                        >
                                            <CIcon name="cil-trash" />
                                        </CButton>
                                    </td>
                                </template>
                                <template #details="{item}">
                                    <div class="p-2" style="background-color: #e0e2e6"  v-if="(item.SerialJson?.filter(item => item.Status !== 0) ?? [])?.length>0">
                                        <dataTableExtended
                                            class="align-center-row-datatable lv-2"
                                            small
                                            :items="formatedLevel2(item, item.SerialJson)"
                                            :fields="level2Fields"
                                            :noItemsView="tableText.noItemsViewText"
                                            :items-per-page="tableText.itemsPerPage"
                                        >
                                        
                                        </dataTableExtended>
                                    </div>
                                </template>
                            </dataTableExtended>
                            <p v-if="PackagingJsonList.length === 0" class="mt-2 text-danger text-center required">
                                {{$t('label.must')+' '+$t('label.aggregate')+' '+$t('label.atLeast')+' 1 '+$t('label.detail')}}
                            </p>
                            <p  class="text-right fw-bold">{{ `
                                ${$t('label.totalQuantity')}: ${FormatNumber(PackagingJsonList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Quantity ? currentValue.Quantity : 0), 0), 2)} - 
                                ${$t('label.totalWeight')}: ${FormatNumber(PackagingJsonList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0), 2)} - 
                                ${$t('label.TotalVolume')}: ${FormatNumber(PackagingJsonList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2)}
                            ` }}</p>
                        </CCol>
                    </CTab>
                </CTabs>
            </CCol>
        </CRow>
        <ModalSerials
            :modal="ModalSerial"
            :PackagingList="PackagingList"
            :isEdit="isEdit"
            :referenceWeigth="referenceWeigth"
            :titleModal="$t('label.serials')"
            :Form="Form"
            :ModuleForm="ModuleForm"
            :Quantity="Quantity"
            :SealForm="SealForm"
            :VForm="$v.Form"
            @CloseModal="(ModalSerial=false, ModuleForm.AccesoryList = [])"
            @UpdatePackagingSerialList="(item)=> UpdatePackagingSerialList(item)"
            @isEditSerialitation="(item)=> {isEditSerialitation = item}"
        />
    </div>
</template>
<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import { mapState } from 'vuex';
    import ModalMixin from '@/_mixins/modal';
    import General from '@/_mixins/general';
    import ModalSerials from './modal-serials'
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';
    import BlReceptionFormValidations from '@/_validations/yard-management/warehouse/blReceptionValidations';
    
    //data
    function data() {
        return {
            //General
            collapseDuration: 0,
            ModalSerial: false,
            isEditPackaging: false,
            isCantError: false,
            tabIndex: 0,
            tabIndexSerial:0,
            FgApplySerial: false,
            FgRequiredSerialization: false,
            FgHabilitedForm: false,
            referenceWeigth: 0,
            dataLocalPackaging: {},
            PlannedQuantityReceived: 0,
            QuantityReceived: 0,
            QuantityRemainReceived: 0,
            comparativeRemainReceived: 0,
            comparativeStowage: 0,
            isEditSerialitation: false,
            initEditQuantityPackaging: false,
            Packaging: {
              commodityName: 0,
            },
            measure: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
                maxlength: 18,
            },
            measureDecimals: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false,
                maxlength: 18,
            },

            //List of selects
            UbicationList: [],
            PackagingList: [],
            billOfLandingList: [],
            PackagingJsonList: [],
            SerializedList: [],
            ConditionList: []
        };
    }

    //-----------------------   Method    ------------------------

    function handleTab(tab) {
        this.tabIndex = tab;
    }

    async function getPackagingList() {
        this.loadingOverlay = true;
        let route = '';
        let params = {};

        if(this.isGeneralList){
            route = 'BlCargoHousePendingReception-list';
            params = {
                YardId: this.$store.state.yardManagement.yardData.YardId
            };
        }
        else{
            route = 'YardWBlCargoHousePackaging-by-BlCargoId'; 
            params = {
                BlCargoId: this.dataBl.BlCargoId
            };
        }   

        await this.$http
            .get(route, params)
                .then(async response => {
                let data =  response?.data?.data ? response.data.data : [];
                let DetailJson = data?.filter((e)=> e?.BlCargoId == this.ContainerItem?.BlCargoId && e?.BlName == this.ContainerItem?.BlName)?.[0]?.DetailJson ?? []
                this.PackagingList = DetailJson ?? [];
            })
            .catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            }).finally(() => {
                this.loadingOverlay = false;
            });
    }

    async function getUbicationList() {
        this.loadingOverlay = true;
        await this.$http
            .get('YardWAreaModule-by-YardId',{YardId: this.$store.state.yardManagement.yardCollapse == 4 ? this.$store.state.yardManagement.yardData.YardId : this.ContainerItem.YardId})
                .then(async response => {
                let data =  response?.data?.data ? response.data.data : [];
                this.UbicationList = data;
            })
            .catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            }).finally(() => {
            this.loadingOverlay = false;
            });
    }

    async function getAccesoryList() {
        this.loadingOverlay = true;
        await this.$http
            .get('BlCargoMasterDetailSerialPackagingAccesory-list',{BlCargoMasterDetailSerialId: '',PackagingId: this.ModuleForm.PackagingItem.PackagingId})
                .then(async response => {
                let data =  response?.data?.data  ? response.data.data : [];
                this.ModuleForm.AccesoryList = data;
            })
            .catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            }).finally(() => {
            this.loadingOverlay = false;
            });
    }

    async function getService() {
        if(this.PackagingList.length == 0 || this.UbicationList.length == 0){
            this.$store.state.yardManagement.loading = true;
            (this.PackagingList.length == 0) && await this.getPackagingList();
            (this.UbicationList.length == 0) && await this.getUbicationList();
            this.$store.state.yardManagement.loading = false;
        }
    }

    function clearFilters() {
        this.ModuleForm.YardAreaId = '';
        this.ModuleForm.PackagingId = '';
        this.ModuleForm.BlCargoMasterDetailId = '';
        this.ModuleForm.VisitBillOfLadingPackagingId = '';
        this.ModuleForm.Quantity = 0;
        this.ModuleForm.Length = 0;
        this.ModuleForm.Width = 0;
        this.ModuleForm.Height = 0;
        this.ModuleForm.Volumen = 0;
        this.ModuleForm.Weigth = 0;
        this.ModuleForm.VolumeCheck = true;
        this.ModuleForm.PackagingItem = {}; 
        this.FgApplySerial = false;
        this.Form.Packaging.Serialization.IsNewSerial = false;
        this.isEditPackaging = false;
        this.isCantError = false;
        this.referenceWeigth = 0;
        this.tabIndex = 0;
        this.PlannedQuantityReceived = 0;
        this.QuantityReceived = 0;
        this.QuantityRemainReceived = 0;
        this.comparativeRemainReceived = 0;
        this.Form.Packaging.maxPackagingVolumen = 0;
        this.comparativeStowage = 0;
        this.dataLocalPackaging = {};
        this.FgRequiredSerialization = false;
        this.FgHabilitedForm = false;
        this.Form.Packaging.Imo = '';
        this.Form.Packaging.ImdgId = '';
        this.Form.Packaging.ImdgClass = '';
        this.Form.Packaging.UnNumber = '';
        this.Form.Packaging.PackagingGroup = '';
        this.Form.Packaging.ImdgClassId = '';
        this.Form.Packaging.UnNumberId =  '';
        this.Form.Packaging.PackagingGroupId =  '';
        setTimeout(() => { this.$v.Form.Packaging.$reset(); }, 10);
    }

    function FormatNumber(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    function setNumber(number, decimal) {
        return NumberFormater.setNum(number, decimal);
    }

    async function OnSerials(item){
        this.ModuleForm.PackagingItem = item;
        await this.getAccesoryList();
        this.ModalSerial = true;
    }

    function EditPackaging(item){
        this.isEditPackaging = true;
        this.initEditQuantityPackaging = true;
        this.ModuleForm.PackagingItem = item;
        this.referenceWeigth = item.referenceWeigth;
        this.Form.Packaging.PackagingId = item.PackagingId;
        this.Form.Packaging.BlCargoMasterDetailId = item.BlCargoMasterDetailId;
        this.Form.Packaging.VisitBillOfLadingPackagingId = item.VisitBillOfLadingPackagingId;
        this.Form.Packaging.YardAreaId = item.YardAreaId;
        this.Form.Packaging.Quantity = NumberFormater.setNum(item.Quantity);
        this.PlannedQuantityReceived = item.PlannedQuantityReceived;
        this.QuantityReceived = item.QuantityReceived;
        this.QuantityRemainReceived = item.QuantityRemainReceived;
        this.Form.Packaging.Length =  NumberFormater.setNum(item.Length ?? 0);
        this.Form.Packaging.Width = item.Width;
        this.Form.Packaging.Height = item.Height;
        this.Form.Packaging.Volumen = item.Volumen;
        this.Form.Packaging.Weigth = NumberFormater.setNum(item.Weigth ?? 0);
        this.Form.Packaging.VolumeCheck = item.VolumeCheck;
        this.FgApplySerial = item.FgApplySerial;
        this.Form.Packaging.maxPackagingVolumen = item.maxPackagingVolumen;
        this.Form.Packaging.maxPackagingWeight = item.maxPackagingWeight;
        this.$v.Form.Packaging.$touch();
        //IMDG
        this.Form.Packaging.Imo = item.Imo ?? '';
        this.Form.Packaging.ImdgClass = item.ImdgClass ?? '';
        this.Form.Packaging.UnNumber = item.UnNumber ?? '';
        this.Form.Packaging.PackagingGroup = item.PackagingGroup ?? '';

        this.Form.Packaging.ImdgId = item.ImdgId ?? '';
        this.Form.Packaging.ImdgClassId = item.ImdgClassId ?? '';
        this.Form.Packaging.UnNumberId = item.UnNumberId ?? '';
        this.Form.Packaging.PackagingGroupId = item.PackagingGroupId ?? '';
        setTimeout(() => { this.$v.Form.Packaging.$reset(); }, 10);

        /*let data = this.PackagingList.find((e)=>e.PackagingId == item.PackagingId) ?? []
        this.dataLocalPackaging = data;*/

        let total = (this.PackagingJsonList.reduce((accumulator, currentValue) => accumulator + ((currentValue?.Status != 0 && currentValue?.Quantity && item.BlCargoMasterDetailId == currentValue.BlCargoMasterDetailId) ? currentValue.Quantity : 0), 0));
        this.comparativeStowage =  this.noNegative(total, item.QuantityReceived);
        this.Form.Packaging.maxPackagingQuantity =  NumberFormater.setNum(item.Quantity) + item.QuantityRemainReceived;
    }

    function UpdatePackagingSerialList(itemsSerial) {
        let data = this.PackagingList.find((e)=>e.BlCargoMasterDetailId == itemsSerial.packagingData.BlCargoMasterDetailId) ?? []
        let Volumen = NumberFormater.setNum(itemsSerial.TotalVolume ?? 0)??0;
        let Weigth = NumberFormater.setNum(itemsSerial.totalWeight?? 0)??0;
        let Quantity = NumberFormater.setNum(this.ModuleForm.PackagingItem.Quantity?? 0)??0;
        let indexOfData = 0
       

        if(itemsSerial.SerialList?.length == 0){
            Volumen = 0;
            Weigth = itemsSerial.packagingData.Quantity * data.UnitWeigth ?? 0;
        }

        let VolumenCondition = ((this.ModuleForm.PackagingItem.Volumen < Volumen) || (itemsSerial.SerialList?.length == Quantity) || (itemsSerial.SerialList?.length == 0));
        let WeigthCondition = ((this.ModuleForm.PackagingItem.Weigth < Weigth) || (itemsSerial.SerialList?.length == Quantity) || (itemsSerial.SerialList?.length == 0));

        this.PackagingJsonList = this.PackagingJsonList.map((item, index) => {
            if((item.BlCargoMasterDetailId == this.ModuleForm.PackagingItem.BlCargoMasterDetailId) && item.Status != 0){
                indexOfData = index;
                return {
                    ...item,
                    SerialJson: itemsSerial.SerialList, 
                    Volumen: VolumenCondition ? Volumen : item.Volumen,
                    Weigth: WeigthCondition ? Weigth : item.Weigth,  
                    Length: VolumenCondition ? 0 : item.Length,
                    Width: VolumenCondition ? 0 : item.Width,
                    Height: VolumenCondition ? 0 : item.Height,
                    VolumeCheck: VolumenCondition ? false : item.VolumeCheck,
                }
              
            } 
            else 
                return item;
        })

        setTimeout(() => { this.$v.Form.Packaging.$reset();}, 10);
        this.$emit('UpdatePackagingList',this.PackagingJsonList)

        if(this.isEditPackaging){
            setTimeout(() => { this.EditPackaging(this.PackagingJsonList[indexOfData]);}, 10);
        }

        this.ModalSerial = false;
        this.ModuleForm.SerialList = [];
    }

    function DeletePackaging(item) {
        this.$swal
            .fire(this.alertProperties({
                text: `${this.$t('label.deleteQuestion')}?`,
            }))
        .then((result) => {
        if (result.isConfirmed) {
            if (item.PackagingId) {
                this.PackagingJsonList = this.PackagingJsonList.map(Packaging => {
                    if (
                        item.PackagingId == Packaging.PackagingId  && 
                        item.BlCargoMasterDetailId == Packaging.BlCargoMasterDetailId) {
                    return {
                        ...Packaging,
                        Status: 0,
                    }
                    } else {
                    return Packaging;
                    }
                })
            
                this.$emit('UpdatePackagingList',this.PackagingJsonList)
            }else{
                this.PackagingJsonList = this.PackagingJsonList.filter(e => e.IdX != item.IdX);
                this.$emit('UpdatePackagingList',this.PackagingJsonList)
            }
            this.clearFilters();
        }
        });
    }

    async function changePackagingsValues(event){
        let data = this.PackagingList.find((e)=>e.BlCargoMasterDetailId == event?.target?.value) ?? []

        if(data){
            this.ModuleForm.PackagingId = data.PackagingId;
            this.tabIndex = 0;
            let totalQuantity = this.PackagingJsonList?.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.BlCargoMasterDetailId ==  data.BlCargoMasterDetailId && currentValue?.Quantity ? currentValue.Quantity : 0), 0) ?? 0;
            this.Form.Packaging.maxPackagingQuantity = data.QuantityRemainReceived - totalQuantity;
            this.FgApplySerial = data.FgApplySerial ? true : false;
            this.SerializedList = data.SerialJson;
            this.FgRequiredSerialization = true;//this.FgRequiredSerialization = data.FgRequiredSerialization;
            this.referenceWeigth = data.UnitWeigth ?? 0;
            //this.Form.Packaging.maxPackagingWeight = data.Weigth ?? this.ContainerItem.Weight?? 0;
            this.Form.Packaging.maxPackagingWeight = this.dataBl.Weigth??  data.Weigth ?? 0;
            //this.Form.Packaging.maxPackagingVolumen = data.Volumen ?? this.ContainerItem.Volumen ?? 0;
            this.Form.Packaging.maxPackagingVolumen = this.ContainerItem.Volumen ?? data.Volumen ?? 0;
            this.PlannedQuantityReceived = data.PlannedQuantityReceived;
            this.QuantityReceived = data.QuantityReceived;
            this.QuantityRemainReceived = data.QuantityRemainReceived - totalQuantity;
            this.Form.Packaging.Quantity = 0;
            setTimeout(() => {this.$v.Form.Packaging.$reset();});
            this.ModuleForm.PackagingItem = {}; 
            this.dataLocalPackaging = data;
            //IMDG

            this.Form.Packaging.Imo = data.ImdgCode ?? '';
            this.Form.Packaging.ImdgClass = data.ImdgClassCode?? '';
            this.Form.Packaging.UnNumber = data.UnNumber ?? '';
            this.Form.Packaging.PackagingGroup = data.PackagingGroupCode ?? '';

            this.Form.Packaging.ImdgId = data.ImdgId ?? '';
            this.Form.Packaging.ImdgClassId = data.ImdgClassId ?? '';
            this.Form.Packaging.UnNumberId = data.UnNumberId ?? '';
            this.Form.Packaging.PackagingGroupId = data.PackagingGroupId ?? '';

            let totalBefore = (this.Form.Packaging.PackagingList.reduce((accumulator, currentValue) => accumulator + ((currentValue?.Status != 0 && data.BlCargoMasterDetailId == currentValue.BlCargoMasterDetailId && currentValue?.Quantity) ? currentValue.Quantity : 0), 0));
            let totalAfter = (this.PackagingJsonList.reduce((accumulator, currentValue) => accumulator + ((currentValue?.Status != 0 && data.BlCargoMasterDetailId == currentValue.BlCargoMasterDetailId && currentValue?.Quantity) ? currentValue.Quantity : 0), 0));
        
            this.comparativeStowage =  data.QuantityReceived + (totalAfter - totalBefore);
            this.QuantityReceived =  data.QuantityReceived + (totalAfter - totalBefore);

            this.comparativeRemainReceived = data.QuantityRemainReceived - (totalAfter - totalBefore);
            this.QuantityRemainReceived = data.QuantityRemainReceived - (totalAfter - totalBefore);
            this.Form.Packaging.maxPackagingQuantity =  this.QuantityRemainReceived;
        }
    }
    
    function SubmitPackaging(){
        try {
            this.$v.Form.Packaging.PackagingId.$touch();
            this.$v.Form.Packaging.Quantity.$touch();
            this.$v.Form.Packaging.Volumen.$touch();
            this.$v.Form.Packaging.Length.$touch();
            this.$v.Form.Packaging.Width.$touch();
            this.$v.Form.Packaging.Height.$touch();
            this.$v.Form.Packaging.Weigth.$touch();

            if (
                this.$v.Form.Packaging.PackagingId.$error || 
                this.$v.Form.Packaging.Quantity.$error    ||
                this.$v.Form.Packaging.Volumen.$error     ||
                this.$v.Form.Packaging.Length.$error      ||
                this.$v.Form.Packaging.Width.$error       ||
                this.$v.Form.Packaging.Height.$error      ||
                this.$v.Form.Packaging.Weigth.$error      
               ) 
                throw this.$t('label.errorsPleaseCheck');

            let data = this.PackagingList.find((e)=>e.BlCargoMasterDetailId == this.ModuleForm.BlCargoMasterDetailId) ?? [];

            if(this.PackagingList.length == 0 || this.PackagingList.length == null)
                throw this.$t('label.errorRegistration');

            if (!this.isEditPackaging && this.PackagingJsonList.some(item => ( item.BlCargoMasterDetailId == this.ModuleForm.BlCargoMasterDetailId) && item.Status != 0)) 
                throw this.$t('label.ItemPreviouslyRegistered');

            let packagingData =  this.PackagingList.find( (val) =>  (val.BlCargoMasterDetailId == this.ModuleForm.BlCargoMasterDetailId) && val.Status != 0);
            let UbicationData =  this.UbicationList.find( (val) =>  val.YardAreaId == this.ModuleForm.YardAreaId);
            
            if (this.isEditPackaging && this.PackagingJsonList.some(item => (item.BlCargoMasterDetailId == this.ModuleForm.BlCargoMasterDetailId) && item.Status != 0)) {
                this.isEditPackaging = false;
                
                this.PackagingJsonList = this.PackagingJsonList.map(item => {
                    if ((item.BlCargoMasterDetailId == this.ModuleForm.BlCargoMasterDetailId) && item.Status != 0) 
                       return {
                            ...item,
                            BlNro: this.ContainerItem.BlNro ?? 'N/A',
                            BlCargoMasterDetailId: this.ModuleForm.BlCargoMasterDetailId??'',
                            PackagingId: this.ModuleForm.PackagingId ?? '',
                            PackagingNameEs: packagingData?.PackagingNameEs ?? 'N/A',
                            PackagingNameEn: packagingData?.PackagingNameEn ?? 'N/A',
                            YardAreaId: this.ModuleForm.YardAreaId ?? '',
                            YardAreaName: UbicationData?.YardAreaName ?? 'N/A',
                            SerialJson: item.SerialJson,
                            FgApplySerial: this.FgApplySerial,
                            Quantity: this.ModuleForm.Quantity ? NumberFormater.setNum(this.ModuleForm.Quantity?? 0) : 0,
                            Weigth: NumberFormater.setNum(this.Form.Packaging.Weigth ?? 0),
                            Length: NumberFormater.setNum(this.Form.Packaging.Length ?? 0,),
                            Width: NumberFormater.setNum(this.Form.Packaging.Width ?? 0),
                            Height: NumberFormater.setNum(this.Form.Packaging.Height ?? 0),
                            maxPackagingVolumen: this.Form.Packaging.maxPackagingVolumen,
                            maxPackagingWeight: this.Form.Packaging.maxPackagingWeight,
                            Volumen: !this.Form.Packaging.VolumeCheck ? NumberFormater.setNum(this.Form.Packaging.Volumen ?? 0) : NumberFormater.setNum(this.Meters.Volumen),
                            VolumeCheck: this.Form.Packaging.VolumeCheck,
                            VisitBillOfLadingPackagingId: packagingData.VisitBillOfLadingPackagingId??'',
                            PlannedQuantityReceived: this.PlannedQuantityReceived,
                            QuantityReceived: this.QuantityReceived,
                            QuantityRemainReceived: this.QuantityRemainReceived,
                            referenceWeigth: this.referenceWeigth,
                            Imo: this.Form.Packaging.Imo ?? '',
                            ImdgClass: this.Form.Packaging.ImdgClass ?? '',
                            UnNumber: this.Form.Packaging.UnNumber ?? '',
                            PackagingGroup: this.Form.Packaging.PackagingGroup ?? '',
                            ImdgId: this.Form.Packaging.ImdgId,
                            ImdgClassId : this.Form.Packaging.ImdgClassId ?? '',
                            UnNumberId: this.Form.Packaging.UnNumberId ?? '',
                            PackagingGroupId: this.Form.Packaging.PackagingGroupId ?? '',
                            Status: 1,
                        }
                    else 
                        return item;
                })
            }

            else {
                this.PackagingJsonList.push({
                    IdX: this.PackagingJsonList.length > 0 ? this.PackagingJsonList[this.PackagingJsonList.length - 1].IdX + 1 : 1,
                    BlNro: this.ContainerItem.BlNro ?? 'N/A',
                    BlCargoMasterDetailId: this.ModuleForm.BlCargoMasterDetailId??'',
                    PackagingId: this.ModuleForm.PackagingId ?? '',
                    PackagingNameEs: packagingData?.PackagingNameEs ?? 'N/A',
                    PackagingNameEn: packagingData?.PackagingNameEn ?? 'N/A',
                    YardAreaId: this.ModuleForm.YardAreaId ?? '',
                    YardAreaName: UbicationData?.YardAreaName ?? 'N/A',
                    Quantity: this.ModuleForm.Quantity ? NumberFormater.setNum(this.ModuleForm.Quantity?? 0) : 0,
                    SerialJson: [],
                    FgApplySerial: this.FgApplySerial,
                    Weigth: NumberFormater.setNum(this.Form.Packaging.Weigth ?? 0),
                    Length: NumberFormater.setNum(this.Form.Packaging.Length ?? 0),
                    Width: NumberFormater.setNum(this.Form.Packaging.Width ?? 0),
                    Height: NumberFormater.setNum(this.Form.Packaging.Height ?? 0),
                    maxPackagingVolumen: this.Form.Packaging.maxPackagingVolumen,
                    maxPackagingWeight: this.Form.Packaging.maxPackagingWeight,
                    Volumen: !this.Form.Packaging.VolumeCheck ? NumberFormater.setNum(this.Form.Packaging.Volumen ?? 0) : NumberFormater.setNum(this.Meters.Volumen),
                    VolumeCheck: this.Form.Packaging.VolumeCheck,
                    VisitBillOfLadingPackagingId: packagingData.VisitBillOfLadingPackagingId??'',
                    PlannedQuantityReceived: this.PlannedQuantityReceived,
                    QuantityReceived: this.QuantityReceived,
                    QuantityRemainReceived: this.QuantityRemainReceived,
                    FgRequiredSerialization: this.FgRequiredSerialization,
                    referenceWeigth: this.referenceWeigth,
                    Imo: this.Form.Packaging.Imo ?? '',
                    ImdgClass: this.Form.Packaging.ImdgClass ?? '',
                    UnNumber: this.Form.Packaging.UnNumber ?? '',
                    PackagingGroup: this.Form.Packaging.PackagingGroup ?? '',
                    ImdgId: this.Form.Packaging.ImdgId,
                    ImdgClassId : this.Form.Packaging.ImdgClassId ?? '',
                    UnNumberId: this.Form.Packaging.UnNumberId ?? '',
                    PackagingGroupId: this.Form.Packaging.PackagingGroupId ?? '',
                    Status: 1,
                });
            }
            this.$emit('UpdatePackagingList',this.PackagingJsonList);
            this.FgRequiredSerialization = false;
            this.clearFilters();
        } 
        catch (error) {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error"
            });
        }
    }
    
    //-----------------------   Computed   ------------------------

    function Packagingfields(){
        return [
          { key: 'options', label: '', _style: 'min-width:135px; text-align:center;', sorter: false, filter: false },
          { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
          { key: 'PackagingName', label: this.$t('label.packaging'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
          { key: 'Quantity', label: this.$t('label.quantity'), _style:'min-width:150px;', _classes:"center-cell text-center", sorter: true, filter: true},
          { key: 'VesselUbicationName', label: this.$t('label.location'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
          { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
          { key: 'ComputedLength', label: this.$t('label.length'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
          { key: 'ComputedWidth', label: this.$t('label.width'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
          { key: 'ComputedHeight', label: this.$t('label.height'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
          { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
          { key: 'Imo', label: this.$t('label.IMO'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
          { key: 'ImdgClass', label: this.$t('label.hazardClass'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
          { key: 'PackagingGroup', label: this.$t('label.PackagingGroup'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
          { key: 'UnNumber', label: this.$t('label.UnNumber'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
        ];
    }

    function level2Fields(){
        return [
            { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
            { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
            { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
            { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _style:'width:20%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
            { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'width:20%;', _classes:"center-cell text-center", sorter: true, filter: true},
            { key: 'Seals', label: this.$t('label.Seals'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
        ];
    }

    function ComputedPackaging() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        let List = this.PackagingJsonList.filter(item => item.Status !== 0)
        return List.map((item, index) => {
            return {
                ...item,
                Nro: index+1,
                PackagingName: item[`PackagingName${_lang}`] ?? 'N/A',
                VesselUbicationName: item.YardAreaName ?? 'N/A',
                ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
                ComputedLength: item.Length ? NumberFormater.formatNumber(item.Length, 2) : '0,00',
                ComputedWidth: item.Width ? NumberFormater.formatNumber(item.Width, 2) : '0,00',
                ComputedHeight: item.Height ? NumberFormater.formatNumber(item.Height, 2) : '0,00',
                ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
                Quantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
            }
        });
    }

    function formatedLevel2(item, SerialJson) {

        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        let totalWeight = NumberFormater.formatNumber(SerialJson?.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0), 2) ?? 0;
        let TotalVolume = NumberFormater.formatNumber(SerialJson.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2);

        let List = ((NumberFormater.setNum(item.Quantity) != (SerialJson?.filter(item => item.Status !== 0)?.length ?? 0)) != 0) ? [{
            Serial: this.$t('label.notSerialized'),
            Quantity: NumberFormater.setNum(item.Quantity) - SerialJson.length,
            Weigth: NumberFormater.setNum(item.ComputedWeight) - NumberFormater.setNum(totalWeight ?? 0),
            Volumen: NumberFormater.setNum(item.ComputedVolume  ?? 0) - NumberFormater.setNum(TotalVolume  ?? 0),
            Seals: '',
        }] : [];

        List = List.concat(SerialJson?.filter(item => item.Status !== 0));

        return List.map((item, index) => {
            return {
            ...item,
            Nro: index+1,
            Quantity: item.Quantity ?? 0,
            ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
            ComputedLength: item.Length ? NumberFormater.formatNumber(item.Length, 2) : '0,00',
            ComputedWidth: item.Width ? NumberFormater.formatNumber(item.Width, 2) : '0,00',
            ComputedHeight: item.Height ? NumberFormater.formatNumber(item.Height, 2) : '0,00',
            ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
            Seals: ` ${(item.Seal1 && item.Seal1 != '')? ` S1: (${item.Seal1})`: ''}${(item.Seal1 && item.Seal1 != '')? `,`: ''} 
                     ${(item.Seal2 && item.Seal2 != '')? ` S2: (${item.Seal2})`: ''}${(item.Seal2 && item.Seal2 != '')? `,`: ''} 
                     ${(item.Seal3 && item.Seal3 != '')? ` S3: (${item.Seal3})`: ''}${(item.Seal3 && item.Seal3 != '')? `,`: ''} 
                     ${(item.Seal4 && item.Seal4 != '')? ` S4: (${item.Seal4})`: ''}
                  `,
            }
        });
    }

    function ubicationOptions(){
        var chart = [{
        value: '',
        label: this.$t('label.select'),
        }]
        this.UbicationList.map((e) => {
        chart.push({
            value: e.YardAreaId,
            label: e.YardAreaName
        });
        })
        return chart;
    }

    function packagingOptions(){
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        var chart = [{
        value: '',
        label: this.$t('label.select'),
        }]
        this.PackagingList.map((e) => {
        chart.push({
            value: e.BlCargoMasterDetailId,
            label: `${e[`PackagingName${_lang}`]} - ${this.$t('label.IMO')}: ${e.ImdgCode??'N/A'} - ${this.$t('label.UnNumber')}: ${e.UnNumber??'N/A'}`
        });
        })
        return chart;
    }

    function Meters() {
        this.Form.Packaging.Volumen = NumberFormater.setNum( this.Form.Packaging.Length) * NumberFormater.setNum( this.Form.Packaging.Width) * NumberFormater.setNum( this.Form.Packaging.Height);
        return {
            Volumen: NumberFormater.setNum( this.Form.Packaging.Length) * NumberFormater.setNum( this.Form.Packaging.Width) * NumberFormater.setNum( this.Form.Packaging.Height)
        }
    }

    function SerialitationVolumen() {
        let totalVolumen = FormatNumber(this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2);
        return this.isEditSerialitation ? (totalVolumen - this.ModuleForm.Serialization.Volumen) : totalVolumen;
    }

    function SerialitationWeigth() {
        let totalWeigth = FormatNumber(this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0), 2);
        return this.isEditSerialitation ? (totalWeigth - this.ModuleForm.Serialization.Weight) : totalWeigth;
    }

    function changeVolumeCheck() {
        this.Form.Packaging.Length = 0;
        this.Form.Packaging.Width = 0;
        this.Form.Packaging.Height = 0;
        this.Form.Packaging.Volumen = 0;
    }

    function noNegative(a, b) {
        return (a - b) < 0 ? ((a - b) *(-1)) : (a - b);
    }

    export default{
        name: 'bl-packaging',
        data,
        mixins: [General, ModalMixin],
        directives: UpperCase,
        props: {
            Form: {
                type: Object,
                required: true,
                default: () => {},
            },
            ModuleForm: {
                type: Object,
                required: true,
                default: () => {},
            },
            SealForm: {
                type: Object,
                required: true,
                default: () => {},
            },
            ContainerItem: {
                type: Object,
                required: true,
                default: () => {},
            },
            isGeneralList: {
                type: Boolean,
                required: false,
                default: () => false,
            },
            Quantity: Number,
            isEdit: Boolean,
            isWasRequested:Boolean,
            Tab: Boolean,
            Active: Boolean,
        },
        components: {
            ModalSerials
        },
        validations() {
            return BlReceptionFormValidations(
                false, 
                this.Form, 
                this.Form.Packaging.Serialization, 
                this.SerialitationVolumen, 
                this.SerialitationWeigth,
                this.ModuleForm.PackagingItem,
                this.ContainerItem,
                this.PackagingJsonList,
                this.isEditPackaging
            );
        },
        methods: {
            SubmitPackaging,
            clearFilters,
            FormatNumber,
            setNumber,
            EditPackaging,
            DeletePackaging,
            getService,
            getPackagingList,
            getUbicationList,
            changePackagingsValues,
            changeVolumeCheck,
            handleTab,
            OnSerials,
            UpdatePackagingSerialList,
            formatedLevel2,
            getAccesoryList,
            noNegative
        },
        computed: {
            Meters,
            Packagingfields,
            level2Fields,
            ubicationOptions,
            packagingOptions,
            ComputedPackaging,
            SerialitationVolumen,
            SerialitationWeigth,
            ...mapState({
                dataBl: state => state.yardManagement.dataBl,
            })
        },
        watch:{
            Tab: async function (Newval) {
                if (Newval == 1) {
                    this.Form.Packaging.BlName = this.ContainerItem.BlNro;
                    this.$v.Form.Packaging.$reset();
                    await this.getService();
                    this.FgHabilitedForm = false;
                }
            },
            isWasRequested: async function (Newval) {
                if (Newval) {
                    this.ModalSerial = false;
                    this.isEditPackaging = false;
                    this.FgApplySerial = false;
                    this.tabIndex = 0;
                    this.PackagingJsonList = [];
                    this.UbicationList = [];
                    this.PackagingList = [];
                    this.billOfLandingList = [];
                    this.ConditionList = [];
                    this.clearFilters();
                    this.Form.Packaging.Serialization.IsNewSerial = false;
                    this.referenceWeigth = 0;
                    this.Form.Packaging.maxPackagingVolumen = 0;
                    setTimeout(() => { this.$v.Form.Packaging.$reset(); }, 10);
                }
            },
            Active: async function (Newval) {
                if (!Newval) {
                    this.ModalSerial = false;
                    this.isEditPackaging = false;
                    this.FgApplySerial = false;
                    this.tabIndex = 0;
                    this.PackagingJsonList = [];
                    this.UbicationList = [];
                    this.PackagingList = [];
                    this.billOfLandingList = [];
                    this.ConditionList = [];
                    this.clearFilters();
                    this.Form.Packaging.Serialization.IsNewSerial = false;
                    this.referenceWeigth = 0;
                    this.Form.Packaging.maxPackagingVolumen = 0;
                }
                else
                    setTimeout(() => { this.$v.Form.Packaging.$reset(); }, 10);
            },
            Quantity: function (value) {
                if(!this.initEditQuantityPackaging){
                    if(this.isEditPackaging){
                        this.QuantityReceived = this.comparativeStowage + value;
                        this.QuantityRemainReceived = (this.PlannedQuantityReceived - this.QuantityReceived) >= 0 ?  (this.PlannedQuantityReceived - this.QuantityReceived) : 0;
                    }
                    else{
                        this.QuantityReceived = this.comparativeStowage + value;
                        this.QuantityRemainReceived = this.comparativeRemainReceived - value;
                    }

                    if(this.ModuleForm.SerialList?.length == 0 && !(this.isEditPackaging && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0)>0 && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0) == this.setNumber(this.ModuleForm.PackagingItem.Quantity ?? 0))){
                        this.Form.Packaging.Weigth =  value * this.referenceWeigth;

                    }
                    else 
                        if (this.isEditPackaging && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0)>0 && (this.ModuleForm.SerialJson?.filter(item => item.Status !== 0)?.length ?? 0) < this.setNumber(this.ModuleForm.Quantity ?? 0)){
                            this.FgHabilitedForm = true;
                            this.Form.Packaging.Weigth =  value * this.referenceWeigth;
                        }
                            
                        else{
                            let totalSerialWeight = this.ModuleForm.PackagingItem?.SerialJson ? NumberFormater.setNum(NumberFormater.formatNumber(this.ModuleForm.PackagingItem?.SerialJson.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0), 2)) : 0;
                            let TotalSerialVolume = this.ModuleForm.PackagingItem?.SerialJson ? NumberFormater.setNum(NumberFormater.formatNumber(this.ModuleForm.PackagingItem?.SerialJson?.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2)) : 0;

                            this.Form.Packaging.Weigth = totalSerialWeight;
                            this.Form.Packaging.Volumen = TotalSerialVolume;
                            this.Form.Packaging.VolumeCheck = false;
                            this.FgHabilitedForm = false;
                        }
                }
                else
                    this.initEditQuantityPackaging = false
                   
            },
        }
    }
</script>
<style lang="scss">
    
    .block-gray{
        width: 100%;
        background: rgb(198, 198, 201);
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
        border-radius: 5px;
    }
    .block-blue{
        width: 100%;
        background: rgb(102, 155, 180);
        border: 1px solid rgb(25, 26, 27);
    }
    .block-blue .text-black{
        color: rgb(25, 26, 27);
        font-weight: 500 !important;
    }
    .block-green{
        width: 100%;
        background: rgb(66, 170, 145);
        border: 1px solid rgb(25, 26, 27)
    }
    .block-green .text-black{
        color: rgb(25, 26, 27);
        font-weight: 500 !important;
    }
    .block-orange{
        width: 100%;
        background: rgb(255, 128, 0);
        border: 1px solid rgb(25, 26, 27)
    }
    .block-orange .text-black{
        color: rgb(25, 26, 27);
        font-weight: 500 !important;
    }

    .block-grey{
        width: 100%;
        background: rgb(229, 229, 229);
        border: 1px solid rgb(25, 26, 27)
    }
    .block-grey .text-black{
        color: rgb(25, 26, 27);
        font-weight: 500 !important;
    }

    .block-yellow{
        width: 100%;
        background: rgb(255, 253, 176);
        border: 1px solid rgb(25, 26, 27)
    }
    .block-yellow .text-black{
        color: rgb(25, 26, 27);
        font-weight: 500 !important;
    }

    .block-green-two{
        width: 100%;
        background: rgb(106, 153, 70);
        border: 1px solid rgb(25, 26, 27)
    }
    .block-green-two .text-black{
        color: rgb(25, 26, 27);
        font-weight: 500 !important;
    }

    .circle{
        height: 15px;
        width: 15px;
        background: #666666;
        display: inline-block;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid rgb(25, 26, 27)
    }
</style>